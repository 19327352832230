.PopUp .show_project {
    width: 800px;
}
.PopUp .show_project .list_project{
    margin-top: 20px;
}
.PopUp .show_project .show_project{
    border: 1px solid #2d3c53;
    border-radius: 6px;
}
.PopUp .show_project .list_input_data{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
}
.PopUp .show_project .list_input_data .div_input{
    width: auto;
}
.PopUp .show_project .list_input_data .text_area{
    resize: none;
    min-height: 34px;
}
.PopUp .show_project .register_data{
    border: none;
    width: 100%;
    font-size: 15px;
    padding: 8px 10px;
}

@media only screen and (max-width: 830px) {
    .PopUp .show_project{
        width: 90%;
    }
}